button,
a,
input[type="button"],
input[type="submit"],
[role="button"],
[tabindex],
.clickfix {
	touch-action: manipulation;
	user-select: none; /* Disable text selection */
	-webkit-user-drag: none; /* Disable dragging on iOS */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove tap highlight color */
}

body {
	-webkit-touch-callout: none; /* Prevent callout, like copy, when long tapping */
}

#display-wrapper {
	background-image: linear-gradient(
			to bottom right,
			rgba(0, 0, 0, 0.8),
			transparent
		), /* Darker gradient overlay */ url("/img/leaf-wall.jpg"); /* Background image */
	background-size: cover;
	background-position: center;
}

.react-phone-number-input__country-select {
	border-radius: xxxs;
	height: calc(1.5em + 0.75rem + 2px);
}

.primary-link {
	text-decoration: none;
	color: var(--text-primary);
	&:hover {
		text-decoration: underline;
	}
}
